import axios from 'axios'
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Filesystem, Directory } from '@capacitor/filesystem'
import { Browser } from '@capacitor/browser'

const TOKEN_KEY = 'jwt';


function validateNumber(num) {
  return !!(num || num === 0) && typeof num === 'number' && !Number.isNaN(Number(num));
};

function validateString() {
  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return !!(typeof str === 'string' && str);
};

function isRequired() {
  var param = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'param';
  throw new Error("".concat(param, " is required"));
};

function invalidType() {
  var param = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'param';
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'type';
  throw new Error("".concat(param, " must be a valid ").concat(type));
};

function minutesToMiliseconds() {
  var minutes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;
  var parseMinutes = !validateNumber(minutes) || minutes < 1 ? 5 : minutes;
  return parseMinutes * 60000;
};

function getItem() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : isRequired('name localstorage');
  if (!validateString(name)) invalidType('name', 'string');
  var itemStr = window.localStorage.getItem(name);

  if (!itemStr) {
    return null;
  }

  var item = JSON.parse(itemStr);

  if (item.expiry < new Date().getTime()) {
    window.localStorage.removeItem(name);
    return null;
  }

  return item.value;
};

function setItem() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : isRequired('name for window.localStorage');
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : isRequired('value for window.localStorage');
  var expiry = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 5;
  if (!validateString(name)) invalidType('name', 'string');
  var validExpiry = !validateNumber(expiry) ? 60000 : minutesToMiliseconds(expiry);
  var item = {
    value: value,
    expiry: new Date().getTime() + validExpiry
  };
  window.localStorage.setItem(name, JSON.stringify(item));
};

//store data to local storage
export const storeData = (key, value, expiry = 5) => {
  setItem(key, value, expiry);
}

//remove local storage data
export const removeData = (key) => {
  window.localStorage.removeItem(key);
}

//return local storage data
export const getData = (key) => {
  if (getItem(key)) {
    return getItem(key);
  }

  return false;
}

//store login user data
export const login = (theData, expiry = 1440) => {
  setItem(TOKEN_KEY, theData, expiry);
}

//refresh token
export const refreshToken = () => {
  console.log('refreshing');
  let loginData = getLoginData();

  if (loginData) {
    axios.post(process.env.REACT_APP_API_URL + '/auth/token', {
      grant_type: 'refresh_token',
      refresh_token: loginData.refresh_token
    }, {
      headers: {
        Authorization: 'Bearer ' + loginData.access_token
      }
    }).then(response => {
      console.log('refresh-token', response)
      if (response.data.status === 'success') {
        loginData['profile'] = response.data.data;

        storeData('jwt', JSON.stringify(loginData), (60 * 24 * 90));
      }
    }).catch(error => {
      removeData('jwt');
      window.location.href = '//' + process.env.REACT_APP_DOMAIN + '/#/login';
    });
  }
}

//remove login user data
export const logout = () => {
  window.localStorage.removeItem(TOKEN_KEY);
}

//check whether user is logged or not
export const isLogin = () => {
  if (getItem(TOKEN_KEY)) {
    return true;
  }

  return false;
}

//return login user data
export const getLoginData = () => {
  if (getItem(TOKEN_KEY)) {
    return JSON.parse(getItem(TOKEN_KEY));
  }

  return false;
}

//update account
export const updateAccount = () => {
  let loginData = getLoginData();

  if (loginData) {
    axios.get(process.env.REACT_APP_API_URL + '/account/data', {
      headers: {
        Authorization: 'Bearer ' + loginData.access_token
      }
    }).then(response => {
      if (response.data.status === 'success') {
        storeData('account', JSON.stringify(response.data.data), (60 * 24 * 90));
      }
    });
  }
}

//check permissions
export const getAccountData = () => {
  const accountData = getData('account');

  if (accountData) {
    return JSON.parse(accountData);
  }

  return false;
}
//update user profile
export const updateProfile = () => {
  let loginData = getLoginData();

  if (loginData) {
    axios.get(process.env.REACT_APP_API_URL + '/user/profile', {
      headers: {
        Authorization: 'Bearer ' + loginData.access_token
      }
    }).then(response => {
      if (response.data.status === 'success') {
        loginData['profile'] = response.data.data;

        storeData('jwt', JSON.stringify(loginData), (60 * 24 * 90));
      }
    });
  }
}

//check permissions
export const checkPermissions = (perms) => {
  const loginData = getLoginData();

  if (loginData) {
    const permissions = loginData.profile.role.permissions;

    if (permissions) {
      if (permissions.filter(perm => perms.includes(perm.name)).length > 0) {
        return true;
      }
    }
  }

  return false;
}

// launch scanner
export var isScannerRunning = false

export const launchScanner = async () => {
  const permission = await BarcodeScanner.checkPermission({ force: true });
  if (!permission.granted) return

  document.getElementsByTagName('body')[0].style.display = 'none'

  isScannerRunning = true
  const result = await BarcodeScanner.startScan()
  isScannerRunning = false

  document.getElementsByTagName('body')[0].style.display = 'block'

  if (result.hasContent) {
    return {
      contents: result.content
    }
  }

  return null
}

export const stopScanner = async () => {
  isScannerRunning = false
  document.getElementsByTagName('body')[0].style.display = 'block'
  BarcodeScanner.stopScan()
}

// save base64 to file
export const downloadBase64File = (base64Data, fileName) => {
  if (window.platform.isMobile) {
    Filesystem.writeFile({
      path: 'Download/' + fileName,
      data: base64Data,
      directory: Directory.ExternalStorage,
    }).then(path => {
      alert(`Data berhasil disimpan di ${path.uri}`)
    }).catch(error => {
      console.log(error)
      alert('Gagal menyimpan data! ' + error)
    });
  } else {
    window.location = base64Data
  }
}

export const downloadBlobFile = (blob, fileName) => {
  var reader = new FileReader();
  reader.onloadend = function () {
    downloadBase64File(reader.result, fileName)
  }
  reader.readAsDataURL(blob);
}

export const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const openUrl = (url) => {
  Browser.open({ url: url })
}

export const getDownloadFolder = async () => {
  return await Filesystem.getUri({
    path: 'Download',
    directory: Directory.ExternalStorage,
  })
}
